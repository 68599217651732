<template>
	<b-modal ref="modal-create-task" hide-title ok-title="Zavrieť" size='lg' ok-only
		@ok="handleOk" @show="resetCourse()">
		<template #modal-header="{ close }">
			<h5>Zadanie úlohy</h5>
			<svg-cross-icon @click="close()"/>
		</template>
		<b-form ref="task-form" class="m-5" @submit="handleSubmit">
			<div class="w-100">
				<label v-if="!this.course">Vyberte kurz</label>
				<div class="d-flex flex-row">
					<z-group-course-search-bar v-if="!this.course" @selected="form.selectedCourse = $event" @inputValChange="inputVal = $event" style="z-index: 1;"/>
					<div v-else class="form-group class-select">
						<label>Vyberte triedu</label>
						<b-form-select v-model="selectedGroup" :options="groupsOptions" class="form-control"/>
					</div>
				</div>
			</div>
			<div class="form-group mt-4">
				<label class="d-block">Odkaz pre študentov (max. 1000 znakov)</label>
				<textarea class="note-text mt-3 p-2 w-100" v-model="form.note" rows="5" maxlength="1000" placeholder="Voľný text" autocomplete="off"></textarea>
			</div>
			<z-deadline-picker class="mb-4" :preselectedCurrentDate="true" @date="form.dateFrom = $event" @time="form.timeFrom = $event" style="z-index: -1;">
				<template v-slot:calendar-title>
					<label>Od kedy treba splniť (dátum)</label>
				</template>
				<template v-slot:clock-title>
					<label>Od kedy treba splniť (čas)</label>
				</template>
			</z-deadline-picker>

			<z-deadline-picker @date="form.dateTo = $event" @time="form.timeTo = $event" style="z-index: -1;">
				<template v-slot:calendar-title>
					<label>Do kedy treba splniť (dátum)</label>
				</template>
				<template v-slot:clock-title>
					<label>Do kedy treba splniť (čas)</label>
				</template>
			</z-deadline-picker>
			
			<a v-if="form.selectedCourse"
				:href="`https://docs.google.com/forms/d/e/${form.selectedCourse.quiz_embed}/viewform`"
				target="_blank" rel="noopener noreferrer"
				class="quiz-link mt-4 d-flex align-items-center w-color-primary"
			>
				<svg-external-link-icon/>
				<span class="font-weight-bold">Ukážka kvízu v úlohe</span>
			</a>
		</b-form>
		<template #modal-footer="{ ok, cancel }">
			<b-button class="modal-btn" variant="primary" type="submit" @click="ok()" :disabled="!isValid || !datesValid">Zadať úlohu</b-button>
			<b-button class="modal-btn" variant="outline-danger" @click="cancel()">Zrušiť</b-button>
		</template>
	</b-modal>
</template>

<script>

import moment from 'moment-timezone'

import wAxios from '@/plugins/w/axios'

const api = {
	getGroups: () => wAxios.get_auth_data(`v1/group-admin/groups`)
}

export default {
	components: {
		'z-deadline-picker':					() => import('@/plugins/lib@groups/groups/z-deadline-picker.vue'),
		'svg-cross-icon': 						() => import('@/plugins/appzmudri/_theme/icon/cross.svg?inline'),
		'z-group-course-search-bar':	() => import('@/plugins/lib@profile/groups/z-group-course-search-bar'),
		'svg-external-link-icon':     () => import('@/plugins/lib@groups/tasks/assets/icons/external-link.svg?inline')
	},

	props: {
		courses : {
			required: false,
			type: Array,
			default: () => []
		},
		course: { 
			required: false,
			type: Object
		},

	},

	mounted() {
		if(this.course) {
			this._loadGroups()
			this.form.selectedCourse = this.course
		}
	},

	data() {
		return {
			form: {
				selectedCourse: null,
				dateFrom: '',
				timeFrom: '',
				dateTo: '',
				timeTo: '',
				note: ''
			},
			inputVal: null,
			selectedGroup: null,
			groupsOptions: [
				{value: null, text: 'Prosím vyberte si triedu', disabled: true}
			]
		}
	},
	methods: {
		showModal() {
			this.$nextTick(() => {
				this.$refs['modal-create-task'].show()
			})
		},
		resetCourse() {
			this.form = {
				selectedCourse: null,
				dateTo: '',
				timeTo: '',
				dateFrom: '',
				timeFrom: ''
			}
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.handleSubmit()
		},
		handleSubmit() {
			if(!this.isValid) return

			const toUtc = (d, t) => {return moment(`${d} ${t}`, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')}

			const task = {
				course_id: this.course ? this.course.id : this.form.selectedCourse.id,
				starts_at: toUtc(this.form.dateFrom, this.form.timeFrom), 
				deadline: toUtc(this.form.dateTo, this.form.timeTo),
				note: this.form.note
			}
			if(this.selectedGroup) task.group_id = this.selectedGroup

			this.$emit('createTask', task)
			this.$nextTick(() => {
				this.$refs['modal-create-task'].hide()
			})
		},
		async _loadGroups() {
			try {
				const groups = await api.getGroups()
				groups.forEach(group => {
					this.groupsOptions.push({ 
						value: group.id,
						text: group.name
					})
				})
			} catch (e) {
				this.$wToast.error(e)
			}
		}
	},
	computed: {
		datesValid() {
			const diffDates = moment(this.form.dateTo, 'YYYY-MM-DD').diff(moment(this.form.dateFrom, 'YYYY-MM-DD'), 'days')
			const diffTimes = moment(this.form.timeTo, 'hh:mm:ss').diff(moment(this.form.timeFrom, 'hh:mm:ss'), 'seconds')
			if(diffDates == 0) {
				return diffTimes > 0
			}
			return diffDates > 0
		},
		isValid() {
			return this.selectedGroup != null || this.form.selectedCourse != null
		}
	}
}
</script>

<style lang="scss" scoped>
	.form-group {
		label {
			padding: 0px;
			font-size: 16px;
		}
	}
	.class-select {
		border-bottom: 1px solid #CADEEA;
		width: 100%;
	}
	svg {
		cursor: pointer;
	}

	h4 {
		font-family: Boing !important;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		color: #2B2E4A;
	}

	.m-width-of-dd-item {
		max-width: 675px;
	}

	.calendar, ::v-deep .custom-select, ::v-deep .b-form-timepicker {
		border: none !important;
		background: none;
		&:active {
			border: none !important;
		}
		//border-bottom: 1px solid #CADEEA !important;
	}

	::v-deep .text-dark, ::v-deep .text-truncate, ::v-deep .text-center {
		color: #2B2E4A !important;
	}

	::v-deep .text-muted {
		color: rgba(156, 161, 174, 0.4) !important;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 18px !important;
		line-height: 27px !important;
	}

	::v-deep .modal-header {
		border-bottom: 0 none;
	}

	::v-deep .light-border {
		border-bottom: 1px solid #CADEEA;
	}

	::v-deep .modal-content {
		top: 150px;
	}

	::v-deep .modal-body {
		p {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			margin-top: auto !important;
			margin-bottom: auto !important;
			align-items: center;

			color: #2B2E4A;
		}
		.modal-box {
			align-items: center;
			width: 569px;
			height: 94px;
			left: 617px;
			top: 847px;

			background: #FFF5D1;
			border: 1px solid #E6D8A7;
			box-sizing: border-box;
			border-radius: 4px;
		}
		.modal-box-text {
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;

			letter-spacing: -0.05em;

			color: #CAB979;
		}
	}
	input {
		border: none;
		width: 100%;
		color: #2B2E4A;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		&::placeholder {
			color: rgba(156, 161, 174, 0.4) !important;
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 27px;
		}
		&:focus {
			outline: 0;
		}
		&:read-only {
			background-color: transparent;
		}
	}


	::v-deep .modal-content {
		border-style: solid;
		border-width: 1px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-image: linear-gradient(90.01deg, #0064FF 60.75%, #FB2564 79.89%) 0 0 100% 0/0 0 4.53px 0 stretch;
	}

	::v-deep .modal-footer {
		border-top: 0 none !important;
		align-items: left;
	}

	.modal-btn {
		width: 221px;
		height: 54px;
		left: 782px;
		top: 767px;
	}

	.box-content {
		margin: auto;
		padding: 20px;
		justify-content: center;
	}

	.box-bottom {
		width: 100%;
		background: #FFF5D1;
		border: 1px solid #E6D8A7;
		box-sizing: border-box;
		align-items: center ;
		border-radius: 4px;
	}

	.quiz-link {
		cursor: pointer;
	}

	.note-text {
		resize: none;
		border: 1px solid rgba(156, 161, 174, 0.4);

		&:focus {
			border: 1px solid rgba(156, 161, 174, 0.4);
		}
	}
</style>